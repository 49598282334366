<template>
  <div class="bac">
    <div>
      <el-card shadow="always" style="width: 1000px">
        <el-cascader :options="options" :show-all-levels="true" v-model="this.type" placeholder="选择领域" @change="this.getInf"/>
        <el-table
            :data="info"
            highlight-current-row
            max-height="700"
            :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}">
          <el-table-column label="封面">
            <template #default="scope">
              <el-image :src="scope.row.pic"></el-image>
            </template>
          </el-table-column>
          <el-table-column label="案例名称" prop="caseName"></el-table-column>
          <el-table-column label="操作" >
            <template #default="scope">
              <el-button @click="this.$router.push({path:'/CaseDetail',query: {caseId: scope.row.id}})">查看</el-button>
              <el-button type="danger" @click="DeleteCase(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>
  </div>
</template>

<script>
//todo:完善这个页面
export default {
  name: "AllProjects",
  data(){
    return{
      submitForm:{
        type2:[''],
        timeType:'一月内',
        stage:'全部',
        transactionMode:'',
        currentPage:1,
        pageSize: 30,
      },

      deleteFrom:{

      },
    }
  },
  mounted() {
    //this.getInf()
  },
  methods:{
    getInfo(){
      this.submitForm.stage = this.$route.query.ProjectStage;
      if (this.checkField.length > 0){
        this.submitForm.type2 = this.checkField;
      }else {
        this.submitForm.type2 = [''];
      }

      if (this.checkTime.length > 0){
        this.submitForm.timeType = this.checkTime[0];
      }else {
        this.submitForm.timeType = '一年内';
      }

      if (this.checkStage.length > 0){
        this.submitForm.stage = this.checkStage[0];
      }else {
        this.submitForm.stage = '全部';
      }

      if (this.checkMode.length > 0){
        this.submitForm.transactionMode = this.checkMode[0];
      }else {
        this.submitForm.transactionMode = '';
      }
      this.$http({
        method: 'post',
        url: '/showProjects',
        data: this.submitForm
      }).then(res => {
        this.projects = res.data.projects;
        for(let i=0;i<this.projects.length;i++){
          if (this.projects[i].stage === 1){
            this.projects[i].stage = "报名中";
          }else if(this.projects[i].stage === 2){
            this.projects[i].stage = "服务中";
          }
          else if(this.projects[i].stage === 3){
            this.projects[i].stage = "即将完成";
          }else if(this.projects[i].stage === 4){
            this.projects[i].stage = "准备中";
          }
          else if(this.projects[i].stage === 5){
            this.projects[i].stage = "已完成";
            this.projects[i].stageStyle = "background-color: #9e9e9e;";
          }
        }
      })
    },

    Delete(index){
      this.deleteFrom.caseId = index;
     /* this.$http({
        method: 'post',
        url: '/deleteCase',
        data: this.deleteFrom,
      }).then(res => {
        if (res.data.code === 1){
          this.$notify({
            title: '删除成功',
            message: res.data.message,
            type: 'success'
          });
          this.getInf();
        }
      })*/
    },
  }
}
</script>

<style scoped>
.bac{
  height: 1194px;
}
</style>